<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo mb-2">
          <b-img fluid :src="logoImg" alt="Better Waste Logo" />
        </b-link>

        <b-card-title class="mb-1"> Forgot Your Access Code? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you a new Access Code
        </b-card-text>

        <p>
          <small class="text-primary" id="success" style="display: none"
            >New Access Code sent. Please check your email inbox then return to
            the login page.</small
          >
          <small class="text-danger" id="failure" style="display: none"
            >Account is locked or can not be found, please contact
            <a
              href="mailto:csteam@betterwaste.co.uk"
              style="color: #ea5455; font-weight: bold"
              >Customer Support</a
            >.</small
          >
        </p>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgotPasswordEmail"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgotPasswordEmail"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit" id="reset-btn">
              Submit
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BImg,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BImg,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      logoImg: require("@/assets/images/bw/bws-logo-blue.jpg"),
      // validation
      required,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (success) {
            // Fetch Customer Details
            const loginButton = document.getElementById("reset-btn");
            const successMessage = document.getElementById("success");
            const errorMessage = document.getElementById("failure");
            loginButton.innerText = "Requesting your new Access Code...";

            fetch(
              "https://prod-10.uksouth.logic.azure.com:443/workflows/2fde0eb9a0fc4272a67af2c8bb0f787c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lI5pU4LzmvYglmnR5VMnOxrzFU-s1WmED_HaNijIK2g",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  UserEmail: this.userEmail,
                }),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error("HTTP status " + response.status);
                }
                return response.json();
              })
              .then((data) => {
                if (data.ErrCode) {
                  errorMessage.style.display = "Block";
                  loginButton.innerText = "Submit";
                } else {
                  successMessage.style.display = "Block";
                  loginButton.innerText = "Submit";
                }
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
